<template>
	<div class="c-app flex-row align-items-center authen-page">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol md="6">
					<CCardGroup>
						<CCard class="p-12">
							<CCardBody>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(login)">
										<h1 class="pb-4">
											{{ $t("Login.Title") }}
										</h1>

										<div class="position-relative">
											<label>{{ $t("Login.Email") }}</label>
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_username"
												:rules="`requiredEmail|isEmail`"
												mode="lazy"
											>
												<div :class="classes">
													<!-- <span class="input-group-text prefix-input">
														<font-awesome-icon :icon="['fas', 'user']" />
													</span> -->
													<input
														v-model.trim="loginForm.username"
														type="text"
														class="form-control"
														autocomplete="username email"
														:placeholder="$t('Login.EmailPlaceholder')"
														:maxlength="maxLength"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</ValidationProvider>
										</div>

										<div class="form-group m-b-0">
											<label>{{ $t("Login.Password") }}</label>
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_password"
												:rules="`requiredChar|max:${maxLength}|min:${minLength}`"
											>
												<div :class="classes">
													<div class="position-relative">
														<!-- <span class="input-group-text prefix-input">
															<font-awesome-icon :icon="['fas', 'key']" />
														</span> -->
														<input
															v-model="loginForm.password"
															class="form-control"
															autocomplete="curent-password"
															:placeholder="$t('Login.PasswordPlaceholder')"
															:type="showPwd ? 'password' : 'text'"
															:maxlength="maxLength"
														/>
														<!-- <div v-if="loginForm.password" @click="showPassword">
															<span v-if="!showPwd" class="input-group-text icon-input">
																<font-awesome-icon
																	:title="$t('Login.hidePwd')"
																	:icon="['fas', 'eye-slash']"
																/>
															</span>

															<span v-else class="input-group-text icon-input">
																<font-awesome-icon
																	:title="$t('Login.showPwd')"
																	:icon="['fas', 'eye']"
																/>
															</span>
														</div> -->
													</div>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</ValidationProvider>
										</div>

										<CRow class="form-group">
											<CCol col="6">
												<input id="checkRemember" v-model="isSave" class="m-l-0" type="checkbox" />
												<label class="form-check-label m-l-20" for="checkRemember">
													{{ $t("Login.remember") }}
												</label>
											</CCol>
											<CCol col="6" class="text-right">
												<button class="px-0 btn btn-link" type="button" @click="gotoForgotPwd">
													{{ $t("Login.ForgotPassword") }}
												</button>
											</CCol>
										</CRow>

										<CRow>
											<CCol>
												<button type="submit" class="btn px-4 btn-outline-custom submit-custom 123">
													{{ $t("Login.Submit") }}
												</button>
											</CCol>
										</CRow>
									</form>
								</ValidationObserver>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
import logo from "@/assets/img/logo_small.jpg"
import { LOGIN } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "Login",

	data() {
		return {
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			minLength: process.env.VUE_APP_INPUT_MIN_LENGTH,
			logo,
			showPwd: true,
			checkSubmit: true,
			isSave: false,

			loginForm: {
				username: null,
				password: null,
			},
		}
	},

	computed: {
		...mapState({ loginFormVuex: "loginForm" }),
	},

	watch: {
		loginForm: function (val) {
			if (val.username && val.password) this.isSave = true
		},
	},

	created() {
		this.loginForm = { ...this.loginFormVuex }
	},

	methods: {
		...mapActions({ LOGIN }),

		showPassword() {
			this.showPwd = !this.showPwd
		},

		login: async function () {
			// Check submit form multiple times
			try {
				if (this.checkSubmit) {
					this.checkSubmit = false
					await this.LOGIN({ ...this.loginForm, isSave: this.isSave })
				}
			} catch (error) {
				this.checkSubmit = true
			}
		},

		gotoForgotPwd() {
			this.$router.push("/forgot-password")
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.prefix-input {
	position: absolute;
	border: transparent;
	background: transparent;
	min-height: 35px;
}

input {
	padding-left: 10px;
}

.submit-custom {
	background-color: #e12383;
	color: $color-white;
}

.m-b-0 {
	margin-bottom: 0;
}

.m-l-0 {
	margin-left: 0;
}

.m-l-20 {
	margin-left: 10px;
}
</style>
